export enum NAVBAR_ITEM_POSITION {
	RIGHT,
	LEFT,
	TOP,
	BOTTOM,
	NONE
}

export class NavbarItemModel {
	name: string;
	url: string;
	position?: NAVBAR_ITEM_POSITION = NAVBAR_ITEM_POSITION.NONE;
}

export const NAVBAR_ITEMS: NavbarItemModel[] = [
	{ name: 'about', url: '/about', position: NAVBAR_ITEM_POSITION.NONE },
	{ name: 'work', url: '/work', position: NAVBAR_ITEM_POSITION.NONE },
	{ name: 'code', url: '/code', position: NAVBAR_ITEM_POSITION.NONE },
	{ name: 'contact', url: '/contact', position: NAVBAR_ITEM_POSITION.NONE }
];
