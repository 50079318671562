import { Component, OnInit, Input } from '@angular/core';
import { Project, PROJECTS } from './project/project.model';

@Component({
	selector: 'app-work',
	templateUrl: './work.component.html',
	styleUrls: ['./work.component.scss'],
})
export class WorkComponent implements OnInit {
	projects: Project[] = PROJECTS;

	constructor() { }

	ngOnInit(): void { }
}
