<div
	class="col-md-4 project-img-container"
	[ngClass]="{
		'order-md-first': isItemOdd,
		'hovered': isItemHovered
	}"
	(mouseover)="addHover(true)"
	(mouseout)="addHover(false)"
	[ngStyle]="{ 'background-image': backgroundImageUrl }"
>
	<!-- <img
		async="on"
		class="img-fluid mb-3 mb-lg-0 d-none d-md-block"
		[src]="'assets/images/works/' + project.imageFile + '.jpg'"
		[alt]="project.linkName"
		(click)="goToPage(project.linkUrl)"
	/>
	<img
		async="on"
		class="img-fluid d-block d-md-none"
		[src]="'assets/images/works/' + project.imageFile + '-400.jpg'"
		[alt]="project.linkName"
		(click)="goToPage(project.linkUrl)"
	/> -->
	<div class="middle"></div>
</div>
<div class="col-md-8 order-first px-md-4">
	<div class="h-100 project">
		<div class="d-flex h-100">
			<div class="project-text my-auto text-md-left">
				<h4 class="text-black">{{ project.title }}</h4>
				<p class="mb-0" *ngFor="let paragraph of project.paragraphs">
					{{ paragraph }}
				</p>
				<a
					class="link-hover-effect"
					[ngClass]="{ hovered: isItemHovered }"
					[href]="project.linkUrl"
					target="_blank"
					(mouseover)="addHover(true)"
					(mouseout)="addHover(false)"
				>
					<i class="fa fa-link" aria-hidden="true"></i>
					{{ project.linkName }}</a
				>
			</div>
		</div>
	</div>
</div>
