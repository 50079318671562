import { NavigationService } from './services/navigation.service';
import { NotificationService } from './notification.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// declare const Waypoint: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'Frontend web curriculum | Susana Sanz';
	isHomeHidded: boolean = false;

	@HostListener('window:scroll', ['$event']) onScrollEvent(
		$event: Event
	): void {
		let isHomeOffset = window.pageYOffset > window.innerHeight - 60;
		if (!this.isHomeHidded === isHomeOffset) {
			this.NavigationService.onChangeHomeVisiblity.emit(
				!this.isHomeHidded
			);
		}
		this.isHomeHidded = !isHomeOffset;
	}

	constructor(
		private notificationService: NotificationService,
		private toastr: ToastrService,
		private NavigationService: NavigationService
	) {
		this.notificationService.successToast.subscribe((res) => {
			this.toastr.success(res, '', {
				closeButton: true,
			});
		});
	}

	ngOnInit(): void {
		// const grinner = new Waypoint({
		// 	element: document.querySelector('#work'),
		// 	handler: function (direction) {
		// 		console.log(
		// 			'MainComponent -> ngOnInit -> direction',
		// 			direction
		// 		);
		// 	},
		// });
	}
}
