export class Project {
	title: string;
	imageFile: string;
	paragraphs: string[];
	linkUrl: string;
	linkName: string;
}

export const PROJECTS: Project[] = [
	{
		title: "Reacting to user's interaction",
		imageFile: 'web-emexs',
		paragraphs: [
			'The customer wanted a changing web where the user could have an experience more than a visit.',
			'The design team wanted differents layers for background images and texts. Also, some animations and blending modes on images. We worked here with CSS3, Bootstrap 3 and some JQuery Plugins -as Fullpage and Wow-.',
		],
		linkUrl: 'https://www.emexs.es',
		linkName: 'Emexs Margeting Digital',
	},
	{
		title: 'Digital Automation Platform with Angular',
		imageFile: 'web-portal',
		paragraphs: [
			'Nodays, creating a Workspace developed with Angular and TypeScript. Managing very complex data on the client side.',
		],
		linkUrl:
			'https://www.asg.com/en/Products/Digital-Automation-Platform.aspx',
		linkName: 'ASG-Zenith Digital Automation Platform',
	},
	{
		title: 'On the client side with AngularJS',
		imageFile: 'web-mytips',
		paragraphs: [
			'Working with AngularJS to stay on the client side during the registration process, which includes: user creation, personal questionnaires and payment data. Using carefully the localstorge. Connecting with Laravel and different apis with secure ajax calls.',
		],
		linkUrl: 'https://www.mytipsandadvice.co.uk/',
		linkName: 'My Tips & Advice',
	},
	{
		title: 'Positioning out of the standards',
		imageFile: 'web-villaemilia',
		paragraphs: [
			'Desing team wanted to broke the standard grid, that means -for the Frontend- to work hard with css positions, some jQuery and take special care of responsive.',
		],
		linkUrl: 'https://www.hotelvillaemilia.com',
		linkName: 'Hotel Villa Emilia',
	},
	{
		title: 'Videos on web',
		imageFile: 'web-yurbban',
		paragraphs: [
			'Working with videos is a special challange for the front work: focusing on their aspect ratio, resolution, position, controls and video formats. And care them for all devices and operating systems.',
		],
		linkUrl: 'https://www.yurbban.com',
		linkName: 'Hotel Yurbban',
	},
];
