import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
	@ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;
	video: HTMLVideoElement;
	isLocalEnv: boolean = true;
	fadeInVideo: boolean = false;
	videoSrc: string = '';
	loading: boolean = true;
	constructor(private navigationService: NavigationService) {
		this.isLocalEnv =
			window.location.origin === 'http://localhost:4200';
		this.videoSrc = this.isLocalEnv
			? 'local_assets/code-HQ.mp4'
			: 'https://res.cloudinary.com/sosua/video/upload/v1555101548/code.mp4';

		this.navigationService.onChangeHomeVisiblity.subscribe(
			(isHomeHidden: boolean) => {
				const isVideoPlaying = !this.video.paused;
				if (!!this.video) {
					this.video.muted = true;
					if (isHomeHidden && isVideoPlaying) {
						this.video.pause();
					}
					if (!isHomeHidden && !isVideoPlaying) {
						this.video
							.play()
							.then((e) => { })
							.catch((error) => {
								console.log('Playing video fails: ', error);
							});
					}
				}
			}
		);
	}

	ngOnInit(): void {
		this.fadeIn(true);
		this.video = this.videoplayer.nativeElement;
		this.loading = false;
	}

	fadeIn(goToIn: boolean): void {
		setTimeout(() => {
			this.fadeInVideo = goToIn;
		}, 1000);
	}

	ngAfterViewInit(): void {
		this.video.muted = true;
		setTimeout(() => {
			this.video.play();
		}, 1000);
	}
}
