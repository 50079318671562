<section
	id="work"
	class="section projects-section bg-light"
	#workSection
>
	<div class="bg-primary section-title">
		<div class="container">
			<div class="row">
				<div class="col-12 section-title-text">
					<h1 class="d-none">Frontend Work</h1>
					<p class="h1">Work</p>
					<p>
						Working with positions, animations, media, data,
						<br />managing client side logic and backend connexions,
						<br />from a Frontend side.
					</p>
				</div>
			</div>
		</div>
	</div>

	<div class="container projects-container">
		<div
			class="row"
			*ngFor="let project of projects; index as index"
			[project]="project"
			[index]="index"
			app-project
		></div>
	</div>
</section>

<!-- <div class="container projects-container py-4 body-text"></div> -->
<!-- class="row justify-content-center no-gutters py-4 py-md-5 row-eq-height" -->
