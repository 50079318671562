<app-home></app-home>
<app-about></app-about>
<app-work></app-work>
<app-code></app-code>
<app-contact></app-contact>

<!-- Footer -->
<footer class="bg-black small text-center text-white-50">
	<div class="container">
		Copyright &copy; Susana Sanz {{ currentYear }}
	</div>
</footer>
