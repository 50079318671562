import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarItemModel, NAVBAR_ITEMS } from '../shared/navbar/navbar-item.model';
declare const Waypoint: any;

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	onChangeHomeVisiblity: EventEmitter<boolean> = new EventEmitter<
		boolean
	>();

	currentRoute: string;
	weAreAtHome: boolean = true;

	constructor(private router: Router, private route: ActivatedRoute) {
		this.currentRoute = this.router.url.substr(1);
		this.onChangeHomeVisiblity.subscribe((e: boolean) => {
			this.weAreAtHome = !e;
			if (this.weAreAtHome) {
				this.goTo('/');
			}
		});
	}

	/* tslint:disable */
	manageWaypoint(): void {
		const $this = this;

		const continuousElementsDown: any = document.getElementsByClassName(
			'section'
		);
		for (var i = 0; i < continuousElementsDown.length; i++) {
			new Waypoint({
				element: continuousElementsDown[i],
				handler: function (direction: string) {
					if (direction === 'down') {
						$this.waypointDownHasChange(this.element.id);
					}
				},
				offset: 57,
			});
		}
		const continuousElementsUp = document.getElementsByClassName(
			'section'
		);
		for (var i = 0; i < continuousElementsUp.length; i++) {
			new Waypoint({
				element: continuousElementsUp[i],
				handler: function (direction: string) {
					if (direction === 'up') {
						$this.waypointUpHasChange(this.element.id);
					}
				},
				offset: 'bottom-in-view',
			});
		}
	}

	/* tslint:enable */
	waypointDownHasChange(itemName: string): void {
		const newPageName: NavbarItemModel = NAVBAR_ITEMS.filter(
			(x: NavbarItemModel) => x.name === itemName
		)[0];
		if (newPageName) {
			this.goTo(newPageName.url);
		}
	}

	waypointUpHasChange(itemName: string): void {
		const newPageName: NavbarItemModel = NAVBAR_ITEMS.filter(
			(x: NavbarItemModel) => x.name === itemName
		)[0];
		if (newPageName) {
			this.goTo(newPageName.url);
		}
	}

	goTo(url: string): void {
		this.router.navigate([url], { relativeTo: this.route });
	}
}
