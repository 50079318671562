<section id="code"
         class="profile-section bg-primary align-center section">
  <div class="container">
    <div class="col-12">
      <h1 class="h1">Code</h1>
      <div class="keywords-group">
        <h2 class="keyword">TypeScript</h2>
        <h2 class="keyword">JavaScript</h2>
        <h2 class="keyword">CSS <small> &</small> SASS</h2>
        <span class="keyword">HTML</span>
        <span class="keyword">JQuery</span>
      </div>

      <h2 class="h1 mt-5">Frameworks</h2>
      <div class="keywords-group">
        <h2 class="keyword">Angular</h2>
        <h2 class="keyword">Bootstrap</h2>
        <h2 class="keyword">Jasmine</h2>
        <span class="keyword">Symfony</span>
        <h2 class="keyword">AngularJS</h2>
      </div>

      <h2 class="h1 mt-5">Tools</h2>
      <div class="keywords-group">
        <span class="keyword">Git</span>
        <span class="keyword">Bitbucket</span>
        <span class="keyword">Slack</span>
        <span class="keyword">Jira</span>
        <span class="keyword">SCRUM</span>
      </div>
    </div>
  </div>
</section>