import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';
import { NavigationService } from '../services/navigation.service';
declare const Waypoint: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private scrollToService: ScrollToService,
    private navigationService: NavigationService
  ) { }

  currentYear: number = new Date().getFullYear();
  currentRoute: string;

  ngOnInit(): void {
    this.currentRoute = this.router.url.substr(1);
    this.scrollToService.scrollTo({
      target: this.currentRoute,
      offset: -57,
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.navigationService.manageWaypoint();
    }, 3000);
  }

  scrollToTop(): void {
    const config: ScrollToConfigOptions = {
      target: 'top',
    };
    this.scrollToService.scrollTo(config);
  }
}
