import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
	AbstractControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ContactFormModel } from './contact-form.model';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
	emailPageUrl: string;
	contactForm: FormGroup;

	contactFormValidate: boolean = false;
	formSent: boolean = false;

	constructor(private formBuilder: FormBuilder, private http: HttpClient) {
		this.contactForm = this.formBuilder.group({
			name: ['', Validators.required],
			phone: [''],
			email: ['', Validators.email],
			message: ['', Validators.required],
		});
		this.http = http;
	}

	ngOnInit(): void {
		this.emailPageUrl = 'mail/contact_me.php';
	}

	onSubmit(customerData: ContactFormModel): void {
		console.log('ContactComponent -> onSubmit -> ContactFormModel', ContactFormModel);
		this.contactFormValidate = true;

		if (this.contactForm.status === 'INVALID') {
			this.validateAllFormFields(this.contactForm);
		} else {
			console.log('formSent');
			this.formSent = true;

			// SEND EMAIL
			this.http
				.post(this.emailPageUrl, customerData, {
					responseType: 'text',
				})
				.subscribe(
					(response: string) => {
						console.log(response);
						this.formSent = true;
					},
					(response: string) => {
						console.log(response);
					}
				);
		}
	}

	validateAllFormFields(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach((field: string) => {
			const control: AbstractControl = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}
