// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyButREt-2ChIqQFzoOwC2M6Nsy_s_b97gU',
    authDomain: 'crud-angular-9-625fa.firebaseapp.com',
    databaseURL: 'https://crud-angular-9-625fa.firebaseio.com',
    projectId: 'crud-angular-9-625fa',
    storageBucket: 'crud-angular-9-625fa.appspot.com',
    messagingSenderId: '783138936472',
    appId: '1:783138936472:web:95876c18f516c2042422ed',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
