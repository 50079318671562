import { Component, OnInit, Input } from '@angular/core';
import { Project } from './project.model';

@Component({
	selector: '[app-project]',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
	@Input() project: Project;
	@Input() index: number;

	loading: boolean = true;

	isItemHovered: boolean = false;
	isItemOdd: boolean = false;
	backgroundImageUrl: string;

	constructor() { }

	ngOnInit(): void {
		this.isItemOdd = !(this.index % 2);
		this.loading = false;
		this.backgroundImageUrl =
			'url(/assets/images/works/' + this.project.imageFile + '.jpg)';
	}

	addHover(hovered: boolean): void {
		this.isItemHovered = hovered;
	}

	goToPage(linkUrl: string): void {
		window.open(linkUrl);
	}
}
