import { Component, OnInit } from '@angular/core';
import { NavbarItemModel, NAVBAR_ITEMS } from './navbar-item.model';
import { NavigationService } from 'src/app/services/navigation.service';
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	showUpArrow: boolean = false;
	navbarItems: NavbarItemModel[] = NAVBAR_ITEMS;

	isCollapsed: boolean = false;
	isMobile: boolean = true;

	responsiveMenuOpenend: boolean = false;
	hideResponsiveMenu: boolean = false;

	constructor(private NavigationService: NavigationService) {
		this.NavigationService.onChangeHomeVisiblity.subscribe((x) => {
			this.showUpArrow = x;
		});
	}

	ngOnInit(): void {}

	toggleMResponsiveMenu(): void {
		this.responsiveMenuOpenend = !this.responsiveMenuOpenend;
		if (!this.responsiveMenuOpenend) {
			setTimeout(() => {
				this.hideResponsiveMenu = true;
			}, 400);
		}
	}
}
