import { NgModule } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmationDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

import { ToastrModule, ToastrService, ToastrConfig } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationService } from './notification.service';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './main/pages/home/home.component';
import { AboutComponent } from './main/pages/about/about.component';
import { WorkComponent } from './main/pages/work/work.component';
import { ContactComponent } from './main/pages/contact/contact.component';
import { CodeComponent } from './main/pages/code/code.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ProjectComponent } from './main/pages/work/project/project.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ConfirmationDialogComponent,
    MainComponent,
    HomeComponent,
    AboutComponent,
    WorkComponent,
    ContactComponent,
    CodeComponent,
    ProjectComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  providers: [
    ConfirmationDialogService,
    NgbActiveModal,
    { provide: ToastrService, useClass: ToastrService },
    NotificationService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule {}
