<section id="about"
		 class="bg-light profile-section section">
	<div class="container px-5 pt-2 pb-5 main">
		<div class="row title">
			<div class="col-12">
				<p class="h1 color-primary mb-md-4">
					Hi! My name is Susana<br />
					I'm Frontend Developer
				</p>
			</div>
		</div>

		<div class="row row-eq-height align-items-center mb-3 mb-md-4">
			<div class="col-12 col-md-4 mt-0">
				<figure class="py-4">
					<div class="avatar-container img-fluid rounded-circle"></div>
					<div class="overimg"></div>
				</figure>
			</div>
			<div class="col-12 col-md-6 col-lg-7">
				<div class="body-text">
					<p class="w-lg-70">
						Before dive into the web sector I was working as image
						technician (I have an Upper Grade Educational Cycle in
						<b>Image</b>).
					</p>
					<p>
						Was in my Bachelor in <b>Multimedia</b> and Engineering
						<b>Web</b> when I falt in love of the creation of images
						and interactions from the code language.
					</p>
					<p>
						After some freelance jobs as a developer, I finally turned
						my professional live in 2017 to work as a
						<b>Frontend</b> developer.
					</p>
					<p class="w-lg-70">
						Since then I have kept studing and working, betting for
						<b>JavaScritp Frameworks</b>.
					</p>
				</div>
			</div>
		</div>
	</div>
</section>