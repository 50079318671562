<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="decline()"
  >
    {{ btnCancelText }}
  </button>
  <button
    type="button"
    ngbAutofocus
    class="btn btn-danger"
    (click)="accept()"
  >
    {{ btnOkText }}
  </button>
</div>
