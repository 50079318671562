<div
	class="nav bg-primary d-block d-lg-none"
	[ngClass]="{
		actived: responsiveMenuOpenend
	}"
>
	<div class="menu-icon" (click)="toggleMResponsiveMenu()">
		<span class="menu-icon__line menu-icon__line-left"></span>
		<span class="menu-icon__line"></span>
		<span class="menu-icon__line menu-icon__line-right"></span>
	</div>
	<div
		class="nav__content"
		[ngClass]="{
			'hidden-menu': !responsiveMenuOpenend
		}"
	>
		<ul class="nav__list">
			<li
				class="h1 nav__list-item"
				[ngxScrollTo]="'#top'"
				[ngxScrollToOffset]="0"
			>
				<a
					class="nav__list-item__link"
					id="home-nav"
					(click)="toggleMResponsiveMenu()"
					>Home</a
				>
			</li>

			<li
				class="h1 nav__list-item"
				*ngFor="let item of navbarItems"
				[ngxScrollTo]="'#' + item.name"
				[ngxScrollToOffset]="-40"
			>
				<a
					class="nav__list-item__link"
					(click)="toggleMResponsiveMenu()"
					[routerLink]="item.url"
					[routerLinkActive]="['router-link-active', 'active']"
					[routerLinkActiveOptions]="{ exact: true }"
					>{{ item.name }}</a
				>
			</li>
		</ul>
	</div>
</div>

<div [ngxScrollTo]="'#top'" [ngxScrollToOffset]="0">
	<a
		class="icon-to-top"
		[ngClass]="{ fadeIn: showUpArrow }"
		[routerLink]="['/']"
		routerLinkActive="router-link-active active"
		[routerLinkActiveOptions]="{ exact: true }"
		><span><i class="fa fas fa-chevron-up"></i></span
	></a>
</div>

<nav
	class="navbar navbar-expand-lg fixed-top hover-effect-1 bg-primary d-none d-lg-block"
	id="mainNav"
>
	<div
		class="navbar-collapse"
		[ngClass]="{ collapse: isCollapsed || isMobile }"
	>
		<ul class="navbar-nav ml-auto mb-0">
			<li
				*ngFor="let item of navbarItems"
				[ngxScrollTo]="'#' + item.name"
				[ngxScrollToOffset]="-57"
			>
				<a
					class="nav-link"
					[routerLink]="item.url"
					routerLinkActive="router-link-active active"
					[routerLinkActiveOptions]="{ exact: true }"
					><span class="text-capitalize">{{ item.name }}</span></a
				>
			</li>
		</ul>
	</div>
</nav>
