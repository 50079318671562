export class ContactFormModel {
	email: string;
	message: string;
	name: string;
	phone?: string;

	constructor(data: Partial<ContactFormModel>) {
		Object.assign(this, data);
	}
}
