import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';

const routes: Routes = [
	{
		path: '',
		component: MainComponent,
		data: { title: 'Frontend Susana Sanz' },
		children: [
			{
				path: 'about',
				component: MainComponent,
				data: { title: 'About' },
			},
			{
				path: 'work',
				component: MainComponent,
				data: { title: 'Work' },
			},
			{
				path: 'code',
				component: MainComponent,
				data: { title: 'Code' },
			},
			{
				path: 'contact',
				component: MainComponent,
				data: { title: 'Contact' },
			},
			{ path: '**', redirectTo: '', component: MainComponent },
			{ path: '', redirectTo: '', pathMatch: 'full' },
			{ path: '*path', redirectTo: '' },
		],
	},
	{ path: '**', redirectTo: '', component: MainComponent },
	{ path: '*path', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
