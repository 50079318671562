<!-- Contact -->
<section
	id="contact"
	class="contact-section bg-light align-center section"
>
	<div class="container">
		<div class="row">
			<div class="col-md-10 col-lg-8 mx-auto text-center">
				<h2 *ngIf="!formSent" class="text-primary mb-3 text-center">
					Interested in my profile?
				</h2>

				<form
					*ngIf="!formSent"
					[formGroup]="contactForm"
					id="contactForm"
					(ngSubmit)="onSubmit(contactForm.value)"
					novalidate
				>
					<div class="col-12 form-group">
						<input
							class="effect-1 form-control"
							id="name"
							type="text"
							formControlName="name"
							placeholder="Enter your name *"
							required
						/>
						<span _ngcontent-wiw-c8="" class="focus-border"></span>
					</div>

					<div
						*ngIf="
							contactForm.controls['name'].invalid &&
							(contactForm.controls['name'].dirty ||
								(contactForm.controls['name'].touched &&
									contactFormValidate))
						"
						class="alert alert-danger"
					>
						<div *ngIf="contactForm.controls['name'].errors.required">
							Please enter your name.
						</div>
					</div>

					<div class="col-12 form-group my-4">
						<input
							class="effect-1 form-group"
							id="email"
							type="email"
							formControlName="email"
							placeholder="Enter your e-mail *"
							required
						/>
						<span _ngcontent-wiw-c8="" class="focus-border"></span>
					</div>

					<div
						*ngIf="
							contactForm.controls['email'].invalid &&
							(contactForm.controls['email'].dirty ||
								contactForm.controls['email'].touched) &&
							contactFormValidate
						"
						class="alert alert-danger"
					>
						<div
							*ngIf="contactForm.controls['email'].errors.required"
						>
							Please enter your email address.
						</div>
						<div *ngIf="contactForm.controls['email'].errors.email">
							Please enter a valid email address.
						</div>
					</div>

					<div class="col-12 form-group my-4">
						<input
							class="effect-1"
							id="phone"
							type="tel"
							formControlName="phone"
							placeholder="Enter your phone"
						/>
						<span _ngcontent-wiw-c8="" class="focus-border"></span>
					</div>

					<div class="col-12 form-group my-4">
						<textarea
							class="form-control effect-1"
							rows="3"
							minlength="15"
							maxlength="255"
							id="message"
							formControlName="message"
							placeholder="Enter your message *"
							required
						></textarea>
						<span class="focus-border"></span>
						<p class="help-block text-danger"></p>
					</div>

					<div
						*ngIf="
							contactForm.controls['message'].invalid &&
							(contactForm.controls['message'].dirty ||
								contactForm.controls['message'].touched) &&
							contactFormValidate
						"
						class="alert alert-danger"
					>
						<div
							*ngIf="contactForm.controls['message'].errors.required"
						>
							Please enter a message.
						</div>

						<div
							*ngIf="contactForm.controls['message'].errors.minlength"
						>
							Please enter, at list, 15 characters message.
						</div>
						<div
							*ngIf="contactForm.controls['message'].errors.maxlength"
						>
							Please enter as maximum 255 characteres.
						</div>
					</div>
					<button
						type="submit"
						id="sendMessageButton"
						class="btn btn-primary mx-auto mt-3"
					>
						Contact Me!
					</button>
				</form>

				<!-- TO-DO: review tranlations -->
				<div *ngIf="formSent">
					Your email has been sent correctly.
					<h2>Thanks for contact!</h2>
				</div>

				<!-- <form
          id="contactForm"
          name="sentMessage"
          novalidate
          [formGroup]="checkoutForm"
          (ngSubmit)="onSubmit(checkoutForm.value)"
        >
          <div class="col-12 form-group">
            <input
              class="effect-1"
              id="name"
              type="text"
              formControlName="name"
              placeholder="Enter your name *"
              required
              data-validation-required-message="Please enter your name."
            />
            <span class="focus-border"></span>
            <p class="help-block text-danger"></p>
          </div>
          <div class="col-12 form-group my-4">
            <input
              class="effect-1"
              id="email"
              type="email"
              formControlName="email"
              placeholder="Enter your e-mail *"
              required
              data-validation-required-message="Please enter your email address."
            />
            <span class="focus-border"></span>
            <p class="help-block text-danger"></p>
          </div>
          <div class="col-12 form-group my-4">
            <input
              class="effect-1"
              id="phone"
              type="tel"
              formControlName="phone"
              placeholder="Enter your phone"
              data-validation-required-message="Please enter your phone number."
            />
            <span class="focus-border"></span>
            <p class="help-block text-danger"></p>
          </div>

          <div class="col-12 form-group my-4">
            <textarea
              class="form-control effect-1"
              rows="3"
              id="message"
              formControlName="message"
              placeholder="Enter your message *"
              required
              data-validation-required-message="Please enter a message."
            ></textarea>
            <span class="focus-border"></span>
            <p class="help-block text-danger"></p>
          </div>

          <div id="success"></div>
          <button
            type="submit"
            id="sendMessageButton"
            class="btn btn-primary mx-auto mt-3"
          >
            Contact Me!
          </button>
        </form> -->
			</div>
		</div>
	</div>
</section>
