<section
	id="home"
	class="intro-section text-center bg-primary h-100-vh align-center section"
>
	<div class="container">
		<div class="row intro">
			<div class="col-lg-8 offset-md-3 text-right">
				<h1 class="h1 my-1">Multimedia web frontend</h1>
				<p class="h4 mt-0 subtitle">By Susana Sanz</p>
			</div>
		</div>
	</div>

	<div
		class="video-container"
		id="video_container_1"
		[ngClass]="{ 'd-none': loading }"
	>
		<div
			id="video_overlay"
			class="video_overlay"
			[ngClass]="{ 'fade-in-video': fadeInVideo }"
		></div>

		<video controls muted id="introVideoTag" #videoPlayer>
			<source [src]="videoSrc" type="video/mp4" />
			<!--<source src="movie.ogg" type="video/ogg">-->
			Your browser does not support the video tag.
		</video>
	</div>
</section>
<section class="w-100 section-transparent h-100-vh"></section>
